import { createStore } from 'vuex';
import { getUserProfile } from '@/services/user';

export default createStore({
  state: {
    user: null,
    sheet_info: null,
    bird_cofing: [],
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    sheet_info: (state) => {
      return state.sheet_info;
    },
  },
  mutations: {
    setUser(state, value) {
      state.user = value;
      if (!value) {
        localStorage.removeItem('BIRDAPP_IS_LOGIN');
      }
    },
    setSheetInfo(state, value) {
      state.sheet_info = value;
    },
    setBirdCofing(state, value) {
      state.bird_cofing = value;
    },
  },
  actions: {
    async fetchUser(content, payload) {
      const res = await getUserProfile();
      if (res.code == 0) {
        content.commit('setUser', res.data);
      } else {
        content.commit('setUser', null);
      }
    },
  },
});
