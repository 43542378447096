import request from '@/utils/request';

/**
 * 发送登录验证码
 * @param {*} data
 * @returns
 */
export async function sendVerifyCode(data) {
  return request('/send_verify_code', {
    method: 'POST',
    data,
  });
}

/**
 * 用户登录
 * @param {*} data
 * @returns
 */
export async function login(data) {
  return request('/login', {
    method: 'POST',
    data,
  });
}

/**
 * 用户修改手机号
 * @param {*} data
 * @returns
 */
export async function changeUserCellphone(data) {
  return request('/change_user_cellphone', {
    method: 'POST',
    data,
  });
}
/**
 * 修改用户信息
 * @param {*} data
 * @returns
 */
export async function changeUserProfile(data) {
  return request('/change_user_profile', {
    method: 'POST',
    data,
  });
}

/**
 * 获取用户信息
 * @param {*} data
 * @returns
 */
export async function getUserProfile() {
  return request('/get_user_profile', {
    method: 'GET',
  });
}
