<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { menuData } from '@/router.js';
import arrow from '@/assets/icons/arrow.png';
const route = useRoute();
const router = useRouter();

let activeIndex = route.path.indexOf('/survey/data') != -1 ? ref('/survey/data') : ref(route.path);
const handleSelect = (key) => {
  activeIndex.value = key;
};

router.afterEach((to) => {
  if (to.path.indexOf('/survey/data') != -1) {
    activeIndex.value = '/survey/data';
    return;
  }
  activeIndex.value = to.path;
});
</script>

<template>
  <div class="menu">
    <el-menu
      background-color="#434B70"
      text-color="#fff"
      :default-active="activeIndex"
      class="el-menu-vertical-demo"
      :default-openeds="menuData.map((item) => item.path)"
      @select="handleSelect"
    >
      <template v-for="item in menuData" :key="item.path">
        <router-link v-if="item.path == '/home'" :to="item.path">
          <el-menu-item :index="item.path">
            <template #title>
              <img v-if="activeIndex == item.path" :src="arrow" class="menu_arrow" />
              <img class="menu_icon_home" :src="item.icon" />
              <span class="menu_title">{{ item.title }}</span>
            </template>
          </el-menu-item>
        </router-link>

        <el-sub-menu v-else-if="!item.hideInMenu" :index="item.path">
          <template #title>
            <img class="menu_icon" :src="item.icon" />
            <span class="menu_title">{{ item.title }}</span>
          </template>
          <template v-for="citem in item.children" :key="`${item.path}/${citem.path}`">
            <router-link v-if="!citem.hideInMenu" :to="`${item.path}/${citem.path}`">
              <el-menu-item :index="`${item.path}/${citem.path}`">
                <img
                  v-if="activeIndex == `${item.path}/${citem.path}`"
                  :src="arrow"
                  class="menu_arrow"
                />
                <span class="menu_item">{{ citem.title }}</span>
                <span v-if="citem.develop" class="menu_develop">开发中</span>
              </el-menu-item>
            </router-link>
          </template>
        </el-sub-menu>
      </template>
    </el-menu>
  </div>
</template>
<style lang="less" scoped>
.menu {
  padding: 30px 0 0;

  .el-menu {
    border: none;
  }

  //   .el-sub-menu__title span {
  //     // font-weight: bold;
  //   }
  .el-menu > a {
    text-decoration: none;
  }
  .el-menu--inline > a > .el-menu-item {
    height: 32px;
    line-height: 32px;
    padding-left: 65px;
  }

  .el-menu--vertical > a > .el-menu-item {
    color: #fff;
    padding-left: 50px;
  }
  .el-menu--vertical > a > .el-menu-item.is-active {
    font-weight: bold;
    padding-left: 22px;
  }
  .el-menu--inline > a > .el-menu-item.is-active {
    color: #fff;
    font-weight: bold;
    padding-left: 24px;
  }
  .el-menu--inline > a > .el-menu-item.is-active > .menu_item {
    padding-left: 31px;
    text-decoration: underline;
  }

  .el-sub-menu.is-active > .el-sub-menu__title > .menu_title {
    font-weight: bold;
  }

  &_icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    margin-left: 28px;

    &_home {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }

  &_item {
    padding-left: 18px;
    font-size: 14px;
  }

  &_arrow {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  &_title {
    // font-weight: bold;
    font-size: 16px;
  }

  &_develop {
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid #fff;
    line-height: 16px;
    height: 14px;
    padding: 2px 12px;
    margin-left: 15px;
    text-decoration: none;
    font-weight: 400;
  }
}
</style>
