import { createRouter, createWebHistory } from 'vue-router';
import store from './store';
import Layout from '@/layout/index.vue';
import Develop from '@/pages/Develop.vue';

import iconHome from '@/assets/icons/home.png';
import iconBird from '@/assets/icons/bird.png';
import iconChart from '@/assets/icons/chart.png';
import iconSearch from '@/assets/icons/search.png';
import iconSettings from '@/assets/icons/settings.png';

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/login', name: 'Login', component: () => import('@/pages/login/index.vue') },
  { path: '/agreement', name: 'Agreement', component: () => import('@/pages/agreement/index.vue') },
  { path: '/privacy', name: 'Privacy', component: () => import('@/pages/privacy/index.vue') },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/pages/NotFound.vue') },
];

export const menuData = [
  {
    path: '/home',
    name: 'Home',
    component: Layout,
    title: '个人主页',
    icon: iconHome,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/pages/Home.vue'),
      },
    ],
  },
  {
    path: '/survey',
    name: 'survey',
    component: Layout,
    title: '鸟类调查',
    icon: iconBird,
    children: [
      {
        title: '调查表格',
        path: 'form',
        name: 'survey_form',
        component: () => import('@/pages/survey/Form.vue'),
      },
      {
        title: '调查数据',
        path: 'data',
        name: 'survey_data',
        component: () => import('@/pages/survey/data/Data.vue'),
      },

      {
        title: '调查结果',
        path: 'result',
        name: 'survey_result',
        component: Develop,
        // develop: true,
        component: () => import('@/pages/survey/Result.vue'),
      },
    ],
  },
  {
    title: '调查数据-新建',
    path: '/survey/data/create/:watch_id/:sheet_id?',
    name: 'survey_data_create',
    hideInMenu: true,
    component: () => import('@/pages/survey/data/Create.vue'),
  },
  {
    path: '/data',
    component: Layout,
    title: '调查数据',
    icon: iconChart,
    children: [
      {
        title: '鸟调记录',
        path: 'record',
        component: Develop,
        develop: true,
      },
      {
        title: '数据下载',
        path: 'download',
        component: Develop,
        develop: true,
      },
      {
        title: 'API',
        path: 'api',
        component: Develop,
        develop: true,
      },
    ],
  },
  {
    path: '/analyse',
    component: Layout,
    title: '数据分析',
    icon: iconSearch,
    children: [
      {
        title: '数据透视',
        path: 'vies',
        component: Develop,
        develop: true,
      },
      {
        title: 'GPS分析',
        path: 'gps',
        component: Develop,
        develop: true,
      },
    ],
  },
  {
    path: '/settings',
    component: Layout,
    title: '设置',
    icon: iconSettings,
    children: [
      {
        title: '个人信息',
        path: 'user',
        component: () => import('@/pages/settings/User.vue'),
      },
      {
        title: '微信关联',
        path: 'wechat',
        component: Develop,
        develop: true,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes.concat(menuData),
});

router.beforeEach((to, from, next) => {
  const loginStatus = localStorage.getItem('BIRDAPP_IS_LOGIN');
  const whitePathList = ['/login', '/agreement', '/privacy'];
  // console.log(to.path, loginStatus);
  if (loginStatus == 1) {
    if (to.path == '/login') {
      next({ name: 'Home' });
    } else {
      next();
    }
  } else {
    if (whitePathList.some((path) => path == to.path)) {
      next();
    } else {
      next({ name: 'Login' });
    }
  }
});

export default router;
