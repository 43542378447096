import { createApp } from 'vue';
import './style.css';
import App from './App.vue';
import router from './router';
import store from './store';
import ECharts from 'vue-echarts';
import { use } from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
  TitleComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';

import 'element-plus/theme-chalk/el-message.css';

use([CanvasRenderer, BarChart, TitleComponent, LegendComponent, GridComponent, TooltipComponent]);

use([BarChart]);
createApp(App).use(router).use(store).component('v-chart', ECharts).mount('#app');
