<script setup></script>

<template>
  <el-empty class="empty" description="开发中" />
</template>

<style lang="less" scoped>
.empty {
  min-height: 100vh;
}
</style>
