import axios from 'axios';
import { ElMessage } from 'element-plus';
import router from '../router';
import { useRoute } from 'vue-router';
// import { ElMessage } from 'element-plus/es/components';

export const serverBaseUrl = '/api';

async function request(url, options) {
  return axios({
    url: serverBaseUrl + url,
    ...options,
  });
}

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('TOKEN');
  config.headers['Authorization'] = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.code !== 0) {
        if (response.data.code === 6 || response.data.code === 3) {
          localStorage.removeItem('BIRDAPP_IS_LOGIN');
          return Promise.reject('not login');
        }
        let messages = response.data.message?.split(': ');
        const message = messages.length > 1 ? messages[1] : response.data.message;
        ElMessage.error(message);
      }
      return response.data;
    } else {
      return Promise.reject(new Error(response.status + ' ' + response.statusText));
    }
  },
  (error) => {
    ElMessage.error('服务器错误');
    return Promise.reject(error);
  }
);

export default request;
