<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Menu from '@/components/Menu.vue';
import homeLogo from '@/assets/home_logo.png';
import logOut from '@/assets/icons/logOut.png';
import avatar from '@/assets/avatar.png';

const store = useStore();
const router = useRouter();

const handleLogOut = () => {
  localStorage.removeItem('TOKEN');
  router.replace('/login');
  store.commit('setUser', null);
  console.log('log out');
};

defineOptions({
  name: 'Layout',
});
</script>

<template>
  <div class="page">
    <div class="left_slide">
      <div class="logo">
        <el-image :src="homeLogo"></el-image>
      </div>
      <Menu class="menu" />
      <div class="user">
        <div class="mobile">
          <!-- <el-image class="avatar" :src="avatar"></el-image> -->
          <span>用户：{{ store.state.user?.cellphone }}</span>
          <el-image class="logout" :src="logOut" @click="handleLogOut"></el-image>
        </div>
        <div class="copyright">
          <p>iBirding 爱观鸟™</p>
          <p>大规模鸟类调查数据平台</p>
        </div>
      </div>
    </div>
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<style lang="less" scoped>
.page {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  .left_slide {
    width: 250px;
    background: #434b70;
    display: flex;
    flex-direction: column;
    .logo {
      width: 200px;
      height: 62px;
      padding: 18px 25px 0;
      // background: rgb(229, 229, 229);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .menu {
      flex: 1;
    }
    .user {
      height: 130px;
      padding-top: 100px;
      color: #fff;
      font-size: 14px;
      .mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          margin-right: 7px;
        }
        .logout {
          width: 24px;
          height: 24px;
          margin-left: 8px;
          cursor: pointer;
        }
      }
      .copyright {
        padding-top: 24px;
        text-align: center;
        line-height: 26px;
      }
    }
  }
  .container {
    flex: 1;
    box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.4);
  }
}
</style>
