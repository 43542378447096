<script setup>
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';

const route = useRoute();
const router = useRouter();
const store = useStore();
const loading = ref(false);
const user = ref(store.state.user);

onMounted(async () => {
  document.body.style.setProperty('--el-color-primary', '#434B70');
  document.body.style.setProperty('--el-color-primary-light-9', '#e2e4f1');
  document.body.style.setProperty('--el-color-primary-dark-2', '#5f6788');
  document.body.style.setProperty('--el-color-primary-light-3', '#434B70');
  document.body.style.setProperty('--el-color-primary-light-5', '#aaacb3');
  document.body.style.setProperty('--el-color-primary-light-7', '#aaacb3');
  try {
    loading.value = true;
    await store.dispatch('fetchUser');
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
});
</script>

<template>
  <el-config-provider :locale="zhCn">
    <div v-if="loading"></div>
    <router-view v-else />
  </el-config-provider>
</template>

<style></style>
